<script>

</script>

<div class="pageContainer">
    <h1>CUT</h1>
    <br />
    <div class="container">
        <h2>Taking Shape</h2>
        <p><i>Valentina</i></p>
        <p>Bending over backwards<br />Arch my back like a violet<br />On my toes, tipping, tripping, slipping, dripping, flipping<br />Moving mountains too quickly<br />Scratched up from tectonics shifting<br />Avoiding all heavy lifting<br />A void in all heavy shifting<br /></p>
        <p>Glossy but a little bit rough and a little bit tough<br />Switch it back, mess it up<br />Cutting teeth on shiny objects<br />Body parts looking like transepts<br />Elevated from someones mishap<br />You can find me covered in shrink-wrap<br /></p>
        <p>I’m still taking shape<br />Finding my place before I can break<br />With absolutely nothing at stake, I’m still taking shape<br />Finding my place before I can break<br />With absolutely nothing at stake<br /></p>
        <p><i>Comf</i></p>
        <p>I needed structure <br />You told me to trust ya   <br />But I couldn't believe <br />No way to foresee<br />You’d turn your back on me<br />You’d turn your back on me <br /></p>
        <p>I've had time to adjust <br />Cause change is a must <br />But I'm feeling trapped <br />By thoughts that i trust<br /></p>
        <p>I see myself <br />In those that I help <br />But I just can't <br />Get through to myself<br /><br />You ever say that you’re well x2<br /></p>
        <p>You could<br />Take everything <br />That’s gone to waste<br />I’m taking shape x2 <br /></p>
    </div>
    <div class="container right">
        <h2>D//stress</h2>
        <p>I've been climbing up these walls<br />Body's aching bruised and sore <br />Last few weeks in bed past 4 <br />And I can't take it anymore <br /><br />It's self torment at its core <br />But I won't let it be a chore <br />My brains become an open door <br />I think its all part of the course <br /><br />And everyone says <br />You gotta destress <br />Could I have done this  <br />Without my distress <br /><br />I need to redress <br />How I can't be less <br />Run myself into the ground<br />Like someone's waiting with my crown <br />When there's nothing else around <br />I could scream but there's no sound <br /><br />You thought you stole me<br />Stories you told me<br />You think you own me <br />But that's the old me <br /><br />You owe me<br /><br />I've been placing tiles <br />Spacing out my mind <br />I've been really trying <br /><br />I need to de-stress<br />I wanted distress <br />I guess I asked for this <br />I hope it don't miss <br /><br />I need to de-stress<br />I wanted distress <br />I guess I asked for this<br /><br />I've been placing x3<br /></p>
    </div>
    <div class="container">
        <h2>Guest // Lists</h2>
        <p>I don’t wanna play this game no more x2 <br /><br />I don’t wanna come too far <br />From where I had to start <br />Takes my ego and my heart <br />To pull these aching bones apart <br /> <br />Feels like nobody gets it<br />Trying too hard and I’m restless <br />What will it take to forget this<br />You ain’t on the guest list x 2<br /><br />You ain’t on the guest list x2 <br /><br />Some kind of catharsis <br />For my guilty conscience <br />Feel myself conflict <br />With what I can accomplish x2 <br /><br />It’s just so cyclical <br />We live on ebbs and flows <br />I wanted closure <br />But we can’t talk it over <br /><br />Don’t want to be closer <br />But I won’t roll over <br />Full disclosure <br />Can’t keep my composure <br /><br />So full of platitudes <br />You’ve got contrasting moods <br />Claiming development <br />A word that you misuse x2<br /><br />Don’t let chaos consume<br />Catharsis is subdued <br />It’s all a process <br />Refinement just makes sense x2 <br /><br />I don’t wanna come too far <br />From where I had to start <br />Takes my ego and my heart <br />To pull these aching bones apart <br /><br />Feels like x3 <br /><br />Feels like nobody gets it<br />Trying too hard and I’m restless <br />What will it take to forget this<br />You ain’t on the guest list x 2<br /><br />You ain’t on the guest list x2 <br /></p>
    </div>
    <div class="container right">
        <h2>Break</h2>
        <p>I've lost all patience <br />No time for waiting <br />I won't stop changing <br />Can't be complacent   <br />It's true    <br />I won't stay waiting on you   (x2)<br /> <br />On the surface it shines<br />Rough around the edges <br />I hope that in time <br />It softens and stretches    <br />Got so many stresses<br />Easy to forget it<br />I just can't express it <br />When I get obsessive <br />But I won’t stay waiting on you<br />I won’t stay waiting on you<br /><br />Eager to please ya <br />Must have amnesia <br />The way I let people play <br />With my emotional state <br /><br />And I just can't wait <br />When there’s so much is at stake <br />All it's ever been<br />Is just a chance at a break <br /><br />Eager to please ya <br />Must have amnesia <br />The way I let people play <br />With my emotional state <br /><br />And I just can't wait <br />When there’s so much is at stake <br />All it's ever been<br />Is just a chance at a break <br /><br />Let it happen  <br />I let it happen to me <br />Let it happen <br />I let it happen to me <br />Let it happen x2 <br />Let it happen to me x2 <br /><br />My emotions are free, <br />So just take what you need <br />Just like the first time <br />I let it happen to me<br /><br />My emotions are free, <br />So just take what you need <br />Just like the first time <br />I let it happen <br /></p>
    </div>
    <div class="container">
        <h2>Age Me</h2>
        <p>You told me I just can’t be by myself<br />And I might be alone but I don’t need your help <br />I’ve been working on trying to get better sleep <br />And I’ve been trying with no change for weeks x2 <br /><br />Not the plan that I had when I’m up in my head <br />They fall down, scattered round, it’s my feelings I’ve shed<br />Cracked the tiles underneath, I’m losing my reach <br />And I’m trying, I can’t be too weak <br /><br /><br />I feel flat and I know, that it’s hard to leave home<br />And I’ve come so far, but there’s only one road<br />There’s a fork, there’s a choice, there’s a way to keep hope <br />Through fogged glasses I’m losing my scope <br /><br />And I need it now<br />I need it now x4<br /><br />Growth without the pain <br />Truth without the blame x2<br /><br />Cause I know I’m going crazy <br />But it hurts more that it just don’t phase me <br />Let it age me, not gracefully, <br />I didn’t like 21, I didn’t like 23, <br />25 in two weeks can’t take it with dignity, <br />Not where I want to be <br />But I’ve gotta take responsibility <br /><br />Let it age me, not gracefully, <br />I didn’t like 21, I didn’t like 23 x2 <br /></p>
    </div>
    <div class="container right">
        <h2>Measured</h2>
        <p>The tiles are shattered <br />Hardened by damage <br />The smallest shards <br />Meet the mark <br />We just talk while <br />Stayed in park <br />But it feels hopeless<br /><br />I don’t want you to think I’ll be better <br />If I just settle <br />I won’t have that pleasure <br />While my mind’s not measured x2 <br /><br />My mind’s not measured <br />My mind’s not measured <br />Won’t you shape it up and place it down for me x2 <br /><br />Unbalanced levels<br />Ratios are questionable <br />Won’t you mix it up and fix it up for me <br /><br />Oh please x 2 <br />And I’m choking just to get it out <br />Chips on my shoulder walked through a broken house <br />And it just ain’t home, when you come around <br />Need time to fix all this self doubt <br /><br />The smaller you are <br />The harder it is <br />To break you apart <br />Don’t want to restart<br /><br />When I build out <br />I can’t be too soft <br />Always on watch <br />Keep my thoughts locked <br /><br />The smaller you are <br />The harder it is <br />To break you apart <br />Don’t want to restart<br /><br />When I build out <br />I can’t be too soft <br />Always on watch <br />Keep my thoughts locked <br /><br />My mind’s not measured <br />My mind’s not measured <br />Won’t you shape it up and place it down for me x2 <br /><br />Unbalanced levels<br />Ratios are questionable <br />Won’t you mix it up and fix it up for me <br /><br />Oh please x3 <br /></p>
    </div>
</div>

<style>
    .container {
        width: 80%;
        text-align: left;
        padding-bottom: 200px;
    }
    .right {
        text-align: right;
    }
    
</style>