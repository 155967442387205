<script>
    import { tweened } from 'svelte/motion'
    import { expoInOut } from 'svelte/easing'

    let degreesOne = tweened(0, {
        duration: 400,
        easing: expoInOut
    })

    let degreesTwo = tweened(0, {
        duration: 400,
        easing: expoInOut
    })

    let trianglePositionOne = tweened(0, {
        duration: 400, 
        easing: expoInOut
    })

    let trianglePositionTwo = tweened(0, {
        duration: 400, 
        easing: expoInOut
    })

    let trianglePositionThree = tweened(0, {
        duration: 400, 
        easing: expoInOut
    })

    let trianglePositionFour = tweened(0, {
        duration: 400, 
        easing: expoInOut
    })

    let trianglePositionFive = tweened(0, {
        duration: 400, 
        easing: expoInOut
    })

    let trianglePositionSix = tweened(0, {
        duration: 400, 
        easing: expoInOut
    })

    let baselinePositionOne = tweened(0, {
        duration: 400, 
        easing: expoInOut
    })

    let baselinePositionTwo = tweened(0, {
        duration: 400, 
        easing: expoInOut
    })

    window.setInterval(function(){
        degreesOne.set(Math.random() * 360)
        trianglePositionOne.set(Math.random() * 2000)
        trianglePositionTwo.set(Math.random() * 2000)
        trianglePositionThree.set(Math.random() * 2000)
    }, 1500)

    window.setInterval(function(){
        degreesTwo.set(Math.random() * 360)
        trianglePositionFour.set(Math.random() * 2000)
        trianglePositionFive.set(Math.random() * 2000)
        trianglePositionSix.set(Math.random() * 2000)
    }, 1200)

    window.setInterval(function(){
        baselinePositionOne.set(50 - (Math.random() * 100))
    }, 900)

    window.setInterval(function(){
        baselinePositionTwo.set(50 - (Math.random() * 100))
    }, 600)
</script>

<div class="container">
    <div 
        class="triangle" 
        style="transform: rotate({$degreesOne}deg); border-top: {$trianglePositionOne}px solid transparent; border-bottom: {$trianglePositionTwo}px solid transparent; border-right: {$trianglePositionThree}px solid #333; margin-left: {$baselinePositionOne}%"
    />
    <div 
    class="triangle" 
    style="transform: rotate({$degreesTwo}deg); border-top: {$trianglePositionFour}px solid transparent; border-bottom: {$trianglePositionFive}px solid transparent; border-right: {$trianglePositionSix}px solid #555; margin-left: {$baselinePositionTwo}%"
/>
</div>

<style>
    .container {
        position: fixed;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        justify-content: center;
        align-items: center;
        z-index: 0;
    }
    .triangle {
        width: 0; 
        height: 0;
        position: absolute;
        mix-blend-mode: difference;
        z-index: 1;
}
</style>