<script>
	import { Cut, Seperate, Slice } from './components'
	import { PageTransition } from './functions'

	let fragments = [
		{ name: "CUT", item: Cut, count: 0 },
		{ name: "SEPERATE", item: Seperate, count: 1}
	]

	let shrapnel = fragments[0]

	let clicked = false;
	let trackPlaying = false;

	let selectedImage = 0;

	function selectImage() {
        let choice = Math.floor(Math.random() * 10)
        selectedImage = choice
    }

	function functionOne() {
        setInterval(selectImage, 2000);
    }

    functionOne()

	function launch() {
		clicked = true
		let audio = new Audio("audio/background.mp3");
		audio.volume = 1;
		audio.loop = true;
		audio.play();
		trackPlaying = true;
	}

	window.onclick = function() {
		let audioOptions = ['audio/0.mp3', 'audio/1.mp3', 'audio/2.mp3', 'audio/3.mp3', 'audio/4.mp3', 'audio/5.mp3', 'audio/6.mp3', 'audio/7.mp3', 'audio/8.mp3'];
		let selectedAudio = audioOptions[Math.round(Math.random())];
		let aud = new Audio(selectedAudio)
		aud.volume = 1
		aud.play()
	}
</script>

<Slice/>

{#if clicked}
	<PageTransition refresh={shrapnel}>
		<svelte:component 
			this={shrapnel.item}
		/>
	</PageTransition>
	<PageTransition refresh={selectedImage}>
		<div class="imageContainer">
			<img class="gravel" src="images/{selectedImage}.jpg" alt="CERAMIC">
		</div>
	</PageTransition>
	<div class="fragments">
		{#each fragments as fragment}
			<div class="fragment" on:click={() => (shrapnel=fragments[fragment.count])}>{fragment.name}</div>
		{/each}
		<a href="https://water.dust.supply" target="_blank">WATER</a>
		<a href="https://clay.dust.supply" target="_blank">CLAY</a>
		<a href="https://dust.supply" target="_blank">DUST</a>
	</div>
{:else}
	<PageTransition refresh={clicked}>
		<div class="landing" on:click={()=> launch()}>
			<img src="images/cover.jpg" alt="CERAMIC">
		</div>
	</PageTransition>
{/if}


<style>
	.fragments {
		position: fixed;
		width: 100%;
		height: 75px;
		bottom: 0;
		left: 0;
		z-index: 3;
		display: flex;
		justify-content: space-evenly;
		align-items: center;
		background: linear-gradient(0deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.8) 90%, rgba(255,255,255,0) 100%);
	}
	.fragment {
		font-size: 1rem;
		cursor: pointer;
		color: black;
		font-family: "Libre Franklin";
	}
	.landing {
		position: absolute;
		width: 100%;
		height: 100%;
		margin: 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		z-index: 3;
		cursor: pointer;
	}
	img {
		width: 20vw;
	}

	.gravel {
		width: 30vw;
	}

	.imageContainer {
		position: fixed;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		opacity: 0.5;
	}
</style>