<script>

</script>

<div class="pageContainer">
    <h1>SEPERATE</h1>
</div>

<div class="videoContainer">
    <iframe
        src={`https://www.youtube.com/embed/7JZWO9MIRV0`}
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
        title="CERAMIC"
    />
</div>

<style>
    .pageContainer {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 60vh;
        justify-content: center;
        align-items: center;
        z-index: 4;
        pointer-events: none;
    }
    .videoContainer {
        position: relative;
        display: flex;
        width: 100%;
        z-index: 2;
        justify-content: center;
        align-items: center;
		overflow: hidden;
		position: relative;
		margin-bottom: 5rem;
    }
    .videoContainer::after {
		padding-top: 56.25%;
		display: block;
		content: '';
	}

	.videoContainer iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
</style>