<script>
	import { fly } from 'svelte/transition';
	export let refresh = '';
</script>

{#key refresh}
	<div in:fly={{ y: -50, duration: 250, delay: 250 }} out:fly={{ y: 50, duration: 250 }}>
		<slot />
	</div>
{/key}

<style>
	div {
        position: relative;
        width: 100%;
        height: 100%
	}
</style>